import checkCookie from "../../../utils/checkCookie";

export default async function isAuth(to, from, next) {
    const
        authCookie = checkCookie(),
        user = to.meta.user;
    if(!authCookie && !user.isConnected){
        return next.redirect('/login')}
    if(authCookie && !user.isConnected){return next.redirect('/auth')}
    return next();
}
