export const LOGIN_LOADER = 'login-loader';
export const LOGIN_REGISTER = 'login-register';
export const LOGIN_ERROR = 'login-error';
export const LOGIN_LOGIN = 'login-login';
export const LOGIN_USERNAME = 'login-username';
export const LOGIN_PASSWORD = 'login-password';
export const LOGIN_RE_PASSWORD = 'login-rePassword';
export const LOGIN_ADMIN_KEY= 'login-adminKey';

export const loginInitState = {
    username: '',
    password: '',
    rePassword: '',
    adminKey: '',
    error: null,
    register: false,
    loading: false
};

export function loginReducer(initState, action) {
    const state = {...initState};
    switch (action.type) {
        case LOGIN_USERNAME:
            state.username = action.username;
            return state;
        case LOGIN_PASSWORD:
            state.password = action.password;
            return state;
        case LOGIN_RE_PASSWORD:
            state.rePassword = action.rePassword;
            return state;
        case LOGIN_ADMIN_KEY:
            state.adminKey = action.adminKey;
            return state;
        case LOGIN_LOADER:
            state.error = null;
            state.loading = true;
            return state;
        case LOGIN_REGISTER:
            state.error = null;
            state.register = !state.register;
            return state;
        case LOGIN_ERROR:
            state.loading = false;
            state.error = action.error
            return state;
        case LOGIN_LOGIN:
            state.loading = false;
            state.error = null;
            state.username = '';
            state.password = '';
            state.rePassword = '';
            state.adminKey = '';
            return state;
        default:
            throw new Error();
    }
}
