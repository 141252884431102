import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import * as serviceWorker from './serviceWorker';
import Router from "./components/Router/Router";

import './assets/style/colors.css';
import './assets/style/style.css';
import './assets/style/cross.css';
import './assets/style/transitions.css';

ReactDOM.render(
    <React.StrictMode>
        <RecoilRoot>
            <BrowserRouter>
                <Router />
            </BrowserRouter>
        </RecoilRoot>
    </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
