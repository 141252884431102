import React, {useEffect, useState} from "react";
import {withRouter, Redirect} from 'react-router-dom';
import {useRecoilState} from "recoil/dist";
import {USER_LOGIN, userState} from "../../stores/user.store";
import axios from "axios";
import checkCookie from "../../utils/checkCookie";
import {COOKIE_CREATE, COOKIE_DELETE, cookieAuthSelector} from "../../stores/authCookie.store";
import Loading from "../Loading/Loading";
import './Authenticator.css';

function Authenticator(){
    const
        [wait,setWait] = useState(false),
        [user, setUser] = useRecoilState(userState),
        // eslint-disable-next-line
        [cookie, setCookie] = useRecoilState(cookieAuthSelector);

    useEffect(_ => {
        let mount = true;
        if(mount){
            axios
                .get(`${process.env.REACT_APP_URL_BACK}/user/me`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Credentials': 'true',
                        'Access-Control-Allow-Headers': 'Content-Type, Accept',
                        'Authorization': `Bearer ${checkCookie()}`
                    }})
                .then(res =>{
                    setCookie({type: COOKIE_CREATE,token: res.data.token});
                    setUser({action: USER_LOGIN, user: res.data.user});
                    setWait(true);
                })
                .catch( _=> {
                    setCookie({type: COOKIE_DELETE});
                    setWait(true);
            })
        }
        return ()=> mount = false;
    // eslint-disable-next-line
    },[])

    return (
        <div className="Authenticator">
            {!wait &&<Loading/>}
            {wait && <Redirect to={`${user.isConnected ? '/':'login' }`}/>}
        </div>)
}

export default withRouter(Authenticator)