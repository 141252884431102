import routes from './Routes';

export default function routesFraming(){
    const currentRoutes = routes;
    let hasChild = false;
    do {
        currentRoutes.forEach( route => {
            if(!route.breadcrumb){
                route.breadcrumb = [{path: route.path, title: route.title}];
            }

            if(route.children){
                route.children.forEach(child => {
                    child.path = `${route.path === '/' ? '': route.path}${child.path}`;
                    if(!child.breadcrumb) { child.breadcrumb = [...route.breadcrumb]}
                    child.breadcrumb.push({path: child.path, title: child.title})
                    child.layout = route.layout;
                    currentRoutes.push(child)
                })
                delete route.children;
            }
        });
        hasChild =  !currentRoutes.find(el => !!el.children);
    } while (!hasChild);
    return currentRoutes;
};