import React from "react";
import "./NavBar.style.css";
import {userState} from "../../stores/user.store";
import {useRecoilValue} from "recoil/dist";
import {Link,withRouter} from "react-router-dom";

function NavBar() {
    const user = useRecoilValue(userState);
    return (
        <div className="NavBar">
            <div className="NavBarMenu">
                <div className="NavBarMenuTitle">
                    <Link to='/' title="Retour à l'accueil">
                        <h2 className="NavBarMenuTitleH2">Visa Admin</h2>
                    </Link>
                </div>
            </div>
            <div className="NavBarMenu">
                <Link to='/subscribers' title="listes des abonnés"><i className="fad fa-list-ul NavBarMenuIcon"/></Link>
                <Link to='/rezize' title="Recarder une image"><i className="fad fa-crop-alt NavBarMenuIcon"/></Link>
            </div>
            <div className="NavBarUserContainer">
                <div className="NavBarUserContainerIcon">
                    <i className="fal fa-user-circle NavBarIcon"/>
                </div>
                <div className="NavBarUserContainerUsInfo">
                    {user.username}
                </div>
            </div>

        </div>
    )

}

export default withRouter(NavBar)