import React from 'react';
import './Loading.css';
const points = [1,2,3,4];

export default function Loading() {
    return (
        <div className="Loading">
            <div data-testid="grid" className="Loading__grid">
                {points.map(point => <div data-testid="grid-point" className="Loading__grid__point" key={point}/>)}
            </div>
        </div>
    )
}