import {atom, selector} from "recoil";

export const USER_INIT_STATE = 'userInitState';
export const USER_STATE = 'userState';
export const USER_LOGIN = "login";
export const USER_LOGOUT = "logout";

export const userInitState = atom({
    key: USER_INIT_STATE,
    default: {
        isConnected: false,
        id: null,
        username: null,
        email: null
    }
});

export const userState = selector({
    key: USER_STATE,
    get: ({get}) => get(userInitState),
    set: ({set, reset}, payload) => {
        switch (payload.action) {
            case USER_LOGIN:
                payload.user.isConnected = true;
                return set(userInitState, payload.user)
            case USER_LOGOUT:
                return reset(userInitState)
            default:
                return payload.user
        }
    }
});