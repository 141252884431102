import {atom, selector} from "recoil";
import checkCookie, {createCookies, deleteCookieToken} from "../utils/checkCookie";
export const COOKIE_AUTH_STATE = 'cookieAuthState';
export const COOKIE_AUTH = 'cookieAuth';
export const COOKIE_CREATE = 'create';
export const COOKIE_DELETE= 'delete';

export const cookieAuthState = atom({
    key: COOKIE_AUTH_STATE,
    default: checkCookie()
});

export const cookieAuthSelector = selector({
    key: COOKIE_AUTH,
    get: ({get}) => get(cookieAuthState),
    set: ({set, reset}, action) =>
        set(
            cookieAuthState,
            _=> {
                switch(action.type) {
                    case 'create':
                        createCookies(action.token);
                        return action.token
                    case 'delete':
                        deleteCookieToken();
                        return reset(cookieAuthState);
                default:
                throw new Error();
                }
            }
        )
});


