import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";

export default function InterObs({trigger}) {
    const obsRef = useRef(null);
    useEffect(()=> {
        const threshold = .5;
        const obs = obsRef.current,
            options = {root: null,rootMargin: '0px',threshold},
            observer = new IntersectionObserver(
                (entries) => {entries.forEach(entry => {
                    if (entry.intersectionRatio > threshold) {
                        console.log('trigger')
                        trigger()
                    }})}, options);
        observer.observe(obs);
        return _=> observer.unobserve(obs)
    },[])
    return <div ref={obsRef} className="intObs"/>
}

InterObs.propTypes = {
    trigger: PropTypes.func.isRequired
}