import React from "react";
import logo from "./../../assets/img/logo.png";
import {withRouter} from "react-router-dom";
import PropTypes from 'prop-types';

function Logo({history}) {
    return <img src={logo} alt="logo" onClick={()=> history.push('/')}/>
}
export default withRouter(Logo);

Logo.propTypes = {
    history: PropTypes.object.isRequired,
};
