import React, {useReducer} from 'react';
import {withRouter} from "react-router-dom";
import PropTypes from 'prop-types';
import './Login.css';
import Logo from "../Logo/Logo";
import ClassicInput from "../Inputs/ClassicInput";
import useTitleDocument from "../../hooks/useTitleDocument";
import {useRecoilState, useRecoilValue} from "recoil/dist";
import { userState } from "../../stores/user.store";
import {CSSTransition} from "react-transition-group";
import axios from 'axios';
import {
    LOGIN_ADMIN_KEY,
    LOGIN_ERROR,
    LOGIN_LOADER,
    LOGIN_LOGIN, LOGIN_PASSWORD,
    LOGIN_RE_PASSWORD,
    LOGIN_REGISTER, LOGIN_USERNAME,
    loginInitState, loginReducer
} from "./login.state";
import Loading from "../Loading/Loading";
import {cookieAuthSelector} from "../../stores/authCookie.store";

function Login({history}) {
    useTitleDocument('Login');
    const user= useRecoilValue(userState),
        [state, dispatch] = useReducer(loginReducer, loginInitState),
        // eslint-disable-next-line
        [connectUser, setConnectUser] = useRecoilState(userState),
        // eslint-disable-next-line
        [cookie, setCookie] = useRecoilState(cookieAuthSelector),
        handleSubmit = event => {
            event.preventDefault();
            dispatch({type:LOGIN_LOADER});
            const data = new FormData();
            data.append("username", state.username);
            data.append("password", state.password);
            data.append("rePassword", state.rePassword);
            data.append("adminKey", state.adminKey);

            axios.post(`${process.env.REACT_APP_URL_BACK}/user${state.register ? '/create':''}`,state)
                .then( async res => {
                    dispatch({type: LOGIN_LOGIN});
                    if(!state.register){
                        await setCookie({type: 'create',token: res.data.token});
                        await setConnectUser({type: 'login', user: res.data.token});
                        history.push('/');
                    } else {dispatch({type: LOGIN_ERROR, error:`UTILISATEUR ${state.username} créé avec succès.` })}
                })
                .catch(e=>dispatch({type: LOGIN_ERROR, error: e.response.data ? e.response.data : "opération impossible"}))
        }

    return(
        <div className="Login">
            <div className="LoginContainer">
                <div className="LoginLogo">
                    <Logo/>
                </div>
                <h1 className="LoginTitle">VISA admin center</h1>
                <p className="LoginSubTitle">Powered by <a href="https//kazerlelutin.space">Kazerlelutin</a></p>
                <form className="LoginForm" onSubmit={handleSubmit}>
                    <div className="LoginInput">
                        <label className="LoginInputLabel">Identifiant</label>
                        <ClassicInput
                            label="Identifiant"
                            value={state.username}
                            placeholder={user.name ? user.name : ""}
                            handleChange={(value) => dispatch({type:LOGIN_USERNAME, username: value})}
                        />
                    </div>
                    <div className="LoginInput">
                        <label className="LoginInputLabel">Mot de passe</label>
                        <ClassicInput
                            value={state.password}
                            placeholder="Mot de passe"
                            handleChange={(value) => dispatch({type:LOGIN_PASSWORD, password: value})}
                            type="password"/>
                    </div>
                    <CSSTransition
                        in={state.register}
                        timeout={300}
                        classNames="trPop"
                        unmountOnExit
                    >
                        <div className="LoginInput">
                            <label className="LoginInputLabel">Répétez le mot de passe</label>
                            <ClassicInput
                                value={state.rePassword}
                                placeholder="Mot de passe"
                                handleChange={(value) => dispatch({type:LOGIN_RE_PASSWORD, rePassword: value})}
                                type="password"/>
                            <label className="LoginInputLabel">Clé admin</label>
                            <ClassicInput
                                value={state.adminKey}
                                placeholder="Clé admin"
                                handleChange={(value) => dispatch({type:LOGIN_ADMIN_KEY, adminKey: value})}
                                type="password"/>
                        </div>
                    </CSSTransition>
                    {state.error && <p className="error">{state.error}</p>}
                    <button className="button buttonValid">
                        {state.loading && <Loading/>}
                            {!state.loading &&
                            <span>
                                {state.register ? "S'enregistrer" : "Se connecter"}
                            </span>
                            }
                     </button>
                </form>
                <div className="LoginRegister"
                     onClick={()=>dispatch({type: LOGIN_REGISTER})}>
                    {!state.register ? "S'enregistrer" : "Se connecter"}
                </div>
            </div>
        </div>
    )
}

export default withRouter(Login);

Login.propTypes = {
    history: PropTypes.object.isRequired
}