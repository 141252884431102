import App from "../App/App";
import Login from "../Login/Login";
import Authenticator from "../Authenticator/Authentificator";
import Cropper from "../Cropper/Cropper";
import Subscribers from "../Subscribers/Subscribers";

const routes = [
    {
        path: '/login',
        title: 'Login',
        component: Login,
    },
    {
        path: '/auth',
        component: Authenticator,
        guard: false
    },
    {
        path: '/',
        title: 'Tableau de bord',
        component: App,
        guard: true
    },
    {
        path: '/subscribers',
        component: Subscribers,
        guard: true
    },
    {
        path: '/rezize',
        component: Cropper,
        guard: true
    }
];

export default routes;