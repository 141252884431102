import React, {useState, useRef} from "react";
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import useAxios from "../../stores/useAxios";
import './Cropper.css';

const initState = {
    dataImg: null,
    img: null,
    cropImg: null
}

export default function CropperJs() {

    const
        cropperRef = useRef(null),
        [cropData,setCropData] = useState(initState),
        visa = useAxios(),
        _crop = ()=>{
        const cropDataCopy = {...cropData};
        cropDataCopy.dataImg = cropperRef.current.getCroppedCanvas().toDataURL()
            setCropData(cropDataCopy)
        },
        upload = _=>{
        const data = new FormData();
            data.append('img',cropData.dataImg)
        visa.post('/cropper/upload', data)
            .then(res=>{
                const cropDataCopy = {...cropData};
                cropDataCopy.cropImg = process.env.REACT_APP_URL_BACK + res.data.url
                setCropData(cropDataCopy)
            })
            .catch(err=> console.log(err))
        },
        crop = event=>{
        const data = new FormData()
        data.append('file', event.target.files[0])
        visa.post('/cropper/crop', data)
            .then(res=>{
                const cropDataCopy = {...cropData};
                cropDataCopy.img = process.env.REACT_APP_URL_BACK + res.data.url
                setCropData(cropDataCopy)
            })
            .catch(err=> console.log(err))
    }

    return (
        <div className="Cropper">
            {cropData.img &&
            <Cropper
                ref={cropperRef}
                src={cropData.img}
                style={{height: 500}}
                aspectRatio={8 / 3}
                guides={false}
                crop={_crop}/>
            }
            {!cropData.img &&
                <div className="CropperInput">
                    <input type="file"
                           className="CropperInputInput"
                           onChange={crop}
                           id="cropImg" name="cropImg"
                           accept="image/png, image/jpeg"
                    />
                   <label htmlFor="cropImg" className="CropperInputLabel">
                       Télécharger une image
                   </label>
                </div>
            }
            {cropData.img &&
            <div className="CropperContainerButtons">
                <button className="CropperButtons" onClick={_=> {
                    const cropDataCopy = {...cropData};
                    cropDataCopy.img = null
                    setCropData(cropDataCopy)
                }}>Supprimer</button>
                <button className="CropperButtons" onClick={upload}>Valider</button>
            </div>
            }
            {cropData.img && cropData.cropImg &&
                <div className="CropperInput">
                    <a download="crop.png" href={cropData.cropImg} target="_blank" title="télécharger l'image redimensionnée">
                        Télécharger l'image
                    </a>
                </div>
            }
        </div>
    )
}