import React from "react";
import './Dashboard.style.css';
import NavBar from "../NavBar/NavBar";

export default function Dashboard({children}) {

    return(
        <div className="Dashboard">
            <NavBar/>
            <main className="DashboardMain">
                {children}
            </main>
        </div>
    )
}