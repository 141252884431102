const COOKIE_NAME_H = 'gah_';
const COOKIE_NAME_P = 'gap_';
const COOKIE_NAME_S = 'gas_';

export default function ReadCookieToken(){
    const cookies = document.cookie.split(';');
    let token = [];
    cookies.forEach( cookie => {
        const
            bake = cookie.split(('=')),
            cookieName = bake[0].replace(' ', ''),
            cookieValue = bake[1];

        if(cookieName === COOKIE_NAME_H) {token.push(cookieValue)}
        if(cookieName === COOKIE_NAME_P) {token.push(cookieValue)}
        if(cookieName === COOKIE_NAME_S) {token.push(cookieValue)}

    })
    return token.join('.');
}

export function createCookies(token){

    const d = new Date();
    const tokenArr = token.split('.');

    d.setTime(d.getTime() + (120*24*60*60*1000));
    const expires = "expires="+ d.toUTCString();
    document.cookie = `${COOKIE_NAME_H}=${tokenArr[0]};${expires};path=/`;
    document.cookie = `${COOKIE_NAME_P}=${tokenArr[1]};${expires};path=/`;
    document.cookie = `${COOKIE_NAME_S}=${tokenArr[2]};${expires};path=/`;
    return token
}

export function deleteCookieToken(){
    const d = "expires=Thu, 01 Jan 1970 00:00:01 GMT";
    document.cookie = `${COOKIE_NAME_H}=;${d}`;
    document.cookie = `${COOKIE_NAME_P}=;${d}`;
    document.cookie = `${COOKIE_NAME_S}= ;${d}`

}
