import React from 'react';
import PropTypes from 'prop-types';
import './style/ClassicInput.css';
import { DebounceInput } from 'react-debounce-input';

export default function ClassicInput({handleChange,value,label,type,placeholder,autoFocus,debounce}) {
    return(
        <div className="ClassicInput">
            <DebounceInput
                autoFocus={autoFocus}
                placeholder={placeholder}
                className="ClassicInputInput"
                type={type ? type : "text"}
                debounceTimeout={debounce ? debounce :400}
                onChange={evt => handleChange(evt.target.value)}
                value={value}
            />
            {value && <div className="ClassicInputCross"> <div className="cross" onClick={ _ => handleChange('')}/> </div>}
        </div>
    )
}

ClassicInput.propTypes = {
    handleChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    debounce: PropTypes.number,
    type: PropTypes.string,
    label: PropTypes.string,
    autoFocus: PropTypes.bool,
    placeholder: PropTypes.string
};
