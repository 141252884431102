import axios from 'axios';
import checkCookie from "./../utils/checkCookie";

export default function useAxios() {

    return axios.create({
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Headers': 'Content-Type, Accept',
            'Authorization': `Bearer ${checkCookie()}`
        },
        baseURL: process.env.REACT_APP_URL_BACK,
        responseType: "json/text"
    });
}