import React from 'react';
import './App.css';

import Subscribers from "../Subscribers/Subscribers";
import Cropper from "../Cropper/Cropper";

function App() {
  return (
    <div className="App">
        ICI, des chiffres et actions rapides
    </div>
  );
}

export default App;
