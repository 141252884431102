import React, {useEffect, useState} from "react";
import InterObs from "../InterObs/InterObs";
import Loading from "../Loading/Loading";
import useAxios from "../../stores/useAxios";
import './Subscribers.css';

const subInitState = {
    page:
        {currentPage:0},
    subscribers:[],
    stop: false,
    loading: false
};

export default function Subscribers() {
    const
        [subscribers, setSubscribers] = useState(subInitState),
        visa = useAxios(),
        fetchSubscribers = async ()=> {
            const subsTemp = {...subscribers};
            subsTemp.stop = true;
            subsTemp.loading = true;
            setSubscribers(subsTemp)
            const result = await visa.get(`${process.env.REACT_APP_URL_BACK}/subscriber/${subsTemp.page.currentPage + 1}`)
                result.data.subscribers.forEach(item=> {
                    const doublon = subsTemp.subscribers.find( it => it.id === item.id);
                    if(!doublon){subsTemp.subscribers.push(item)}
                    else{subsTemp.stop = true}
                })
            if( result.data.page.currentPage >= result.data.page.totPage){
                subsTemp.stop = true
            }
            subsTemp.page = result.data.page;
            subsTemp.loading = false;
            subsTemp.stop = false
            setSubscribers({...subsTemp})
        }
        useEffect(() => {
            let mount = true;
            if(mount){ fetchSubscribers()}
            return () => {mount = false; setSubscribers(subInitState)};
            // eslint-disable-next-line
        },[])
    return(
        <div className="Subscribers">
            {subscribers.subscribers.length > 0 && subscribers.subscribers.map( subscriber =>
                <div className="SubscribersTab" key={subscriber.id}>
                    <div className="SubscribersTabName">{subscriber.email}</div>
                </div>
            )}
            {(!subscribers.stop && subscribers.page.currentPage < subscribers.page.totPage) &&
                <InterObs trigger={fetchSubscribers}/>
            }
            {subscribers.loading && <Loading/>}
    </div>
    )
}