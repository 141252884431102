import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { GuardProvider, GuardedRoute } from 'react-router-guards';
import routesFraming from './routesFraming';
import isAuth from "./Middleware/isAuth";
import Dashboard from "../Dashboard/Dashboard";
import {useRecoilValue} from "recoil/dist";
import {cookieAuthState} from "../../stores/authCookie.store";
import {userState} from "../../stores/user.store";

function Router()  {
    const
        authCookie = useRecoilValue(cookieAuthState),
        user = useRecoilValue(userState)
    return (
        <Switch>
            {routesFraming().length > 0 && routesFraming().filter(el => !el.guard).map( route =>
                <Route exact path={route.path} component={route.component} key={route.path} />
            )}
            <GuardProvider guards={isAuth}>
                <Dashboard>
                    {routesFraming().length > 0 && routesFraming().filter(el => el.guard).map( route =>
                        <GuardedRoute
                            exact path={route.path}
                            component={route.component}
                            key={route.path}
                            meta={{authCookie: authCookie, user: user}}
                        />
                    )}
                </Dashboard>
            </GuardProvider>
        </Switch>
    )}

export default withRouter(Router);